
import colors from '/assets/vars/colors.json'
export default
	props: card: Object

	data: -> { jobs: [], colors }

	computed: mobileJobs: -> @jobs.slice(0, if @jobs.length <= 4 then @jobs.length else 4)

	fetch: ->
	 	# The greenhouse API endpoint to fetch the job list from
		endpoint = 'https://boards-api.greenhouse.io/v1/boards/nuro/jobs/?content=true'

		# Fetch the career listing data
		{ data } = await @$axios.get endpoint
		@jobs = @getRandomEntries data.jobs, 5

	methods:
		getRandomEntries: (array, n) ->
			randomEntries = []
			while n > 0 and array.length > 0
				index = Math.floor(Math.random() * array.length)
				randomEntries.push(array.splice(index, 1)[0])
				n--
			randomEntries


