import FancyCard from './fancy-card'
import JobCard from './job-card'
import FaqCard from './faq-card'

export default

	name: 'FancyCard'

	functional: true

	props:
		card: Object

	# Render the appropriate component
	render: (create, { props: { card }, data }) ->

		# Make default data object
		data = {
			...data
			props: { card }
		}

		# Make cards
		switch card.__typename
			when 'blocks_fancyCard_BlockType' then create FancyCard, data
			when 'blocks_jobCard_BlockType' then create JobCard, data
			when 'blocks_faqCard_BlockType' then create FaqCard, data
