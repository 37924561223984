import { render, staticRenderFns } from "./faq-card.vue?vue&type=template&id=30e3644f&scoped=true&lang=pug"
import script from "./faq-card.vue?vue&type=script&lang=coffee"
export * from "./faq-card.vue?vue&type=script&lang=coffee"
import style0 from "./faq-card.vue?vue&type=style&index=0&id=30e3644f&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e3644f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnCraft: require('/opt/build/repo/site/components/globals/btn/craft.vue').default,Squircle: require('/opt/build/repo/site/components/globals/squircle/index.vue').default})
