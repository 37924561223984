
import Card from './card'
export default
	props: block: Object
	components: { Card }

	computed:

		count: -> @block.cards?.length || 0

		classes: -> ["expand-cards-on-mobile" if @count < 6]

		# Separate cards in rows and create grid-template-columns
		# values for each of these rows
		rows: ->
			currentRow = 0
			rows = [@makeNewRow()]
			reference = ""

			@block.cards?.forEach (card) =>
				if !reference then reference = card.size || "small"

				# The current row index
				row = Math.floor(currentRow)

				if rows.length-1 < row
					# rows[rows.length-1].style["--columns"] += ";"
					rows.push @makeNewRow()
					reference = card.size || "small"

				switch card.__typename

					when "blocks_fancyCard_BlockType"
						desktopLength = switch card.size
							when "small" then 1/4
							when "medium" then 1/3
							when "large" then 1/2

						switch reference
							when "small"
								switch card.size
									when "small" then fraction = " 1fr"
									when "medium" then fraction = " 1.32fr"
									when "large" then fraction = " 2fr"

							when "medium"
								switch card.size
									when "small" then fraction = " 0.75fr"
									when "medium" then fraction = " 1fr"
									when "large" then fraction = "1.51fr"

							when "large"
								switch card.size
									when "small" then fraction = " 0.5fr"
									when "medium" then fraction = "0.6fr"
									when "large" then fraction = "1fr"

					when "blocks_jobCard_BlockType"
						desktopLength = 1/4
						fraction = switch reference
							when "small" then " 1fr"
							when "medium" then " 1.32fr"
							when "large" then " 2fr"

					when "blocks_faqCard_BlockType"
						desktopLength = 1/4
						fraction = switch reference
							when "small" then " 1fr"
							when "medium" then " 1.32fr"
							when "large" then " 2fr"

				rows[row]?.cards?.push card
				rows[row]?.style["--columns"] += " #{fraction}"
				currentRow += desktopLength || 0

			return rows

	methods:
		# Create new row object
		makeNewRow: ->
			style: "--columns": ""
			cards: []

