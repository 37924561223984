
import colors from '/assets/vars/colors.json'
import getAllFaqs from '~/components/blocks/faq-listing/all-faqs.gql'

export default
	props: card: Object

	computed: colors: -> colors

	data: ->
		faqs: []

	fetch: ->
		{ @faqs, @categories } = await @$craft.execute query: getAllFaqs

		@faqs = @faqs
			.filter (faq) -> faq.category.some (category) -> category.title == 'Safety'
			.slice(0, 3)


